<template>
  <MainPage />
</template>

<script>
import MainPage from "../components/MainPage";

export default {
  name: "Home",

  components: {
    MainPage,
  },
};
</script>
