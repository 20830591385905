<template>
  <v-checkbox
    v-model="value"
    filled
    :label="label"
    clearable
    @input="submit"
  ></v-checkbox>
</template>

<script>
export default {
  name: "ABRCheckbox",
  props: {
    stringValue: String,
    label: String,
  },
  created() {},

  data() {
    return {};
  },
  computed: {
    value: {
      get() {
        return this.asBoolean(this.stringValue);
      },
      set(newValue) {
        this.$emit("input", this.asString(newValue));
      },
    },
  },
  methods: {
    $t(value) {
      return value;
    },
    submit() {
      return this.$emit("input", this.asString(this.value));
    },
    asBoolean(strvalue) {
      if (strvalue == "true") {
        return true;
      }
      return false;
    },
    asString(boolval) {
      if (boolval) {
        return "true";
      }
      return "false";
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
