var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"app"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"9"}},[(!_vm.openCamera)?_c('v-btn',{attrs:{"color":"primary","dark":""},on:{"click":_vm.newObservation}},[_c('span',[_vm._v("New Observation")])]):_vm._e(),_c('v-form',{ref:"observation"},[(
              (_vm.tabType == 'Downed Bird') &
              (_vm.currentRecord.observation_ts != '')
            )?_c('DownedBirdForm',_vm._g({attrs:{"selected_record":_vm.currentRecord,"records":_vm.currentPhotos,"notSearch":_vm.notSearch}},_vm.handler)):_vm._e(),(
              (_vm.tabType == 'Predator') & (_vm.currentRecord.observation_ts != '')
            )?_c('PredatorForm',_vm._g({attrs:{"selected_record":_vm.currentRecord,"records":_vm.currentPhotos,"notSearch":_vm.notSearch}},_vm.handler)):_vm._e(),(
              (_vm.tabType == 'Turtle') & (_vm.currentRecord.observation_ts != '')
            )?_c('TurtleForm',_vm._g({attrs:{"notSearch":_vm.notSearch,"selected_record":_vm.currentRecord,"records":_vm.currentPhotos}},_vm.handler)):_vm._e()],1)],1),_c('v-col',{attrs:{"cols":"10","md":"3"}},[(_vm.currentRecord.observation_ts != '')?_c('PhotoList',_vm._g({attrs:{"records":_vm.currentPhotos}},_vm.handler)):_vm._e()],1)],1),(_vm.currentRecord.observation_ts != '')?_c('v-btn',{attrs:{"color":"primary","dark":""},on:{"click":_vm.save}},[_c('span',[_vm._v("Done")])]):_vm._e(),_c('v-row',[_c('RecordList',_vm._g({attrs:{"records":_vm.records,"fields":_vm.fields}},_vm.handler))],1),_c('PhotoTypeDialog',_vm._g({attrs:{"showPhotoDialog":_vm.assignPhotoType}},_vm.handler)),(_vm.openCamera)?_c('TakePhoto',_vm._g({attrs:{"previewHeight":600,"previewWidth":800,"maxheight":_vm.maxheight,"maxwidth":_vm.maxwidth,"autoDownload":true,"isCameraOpen":_vm.openCamera}},_vm.handler)):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }