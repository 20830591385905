<template>
  <div class="table-container">
    <v-data-table
      ref="datatable"
      :headers="headers"
      :items="records"
      item-key="ts"
      :item-class="getRowClass"
      @click:row="handleRowClick"
    >
    </v-data-table>
  </div>
</template>
<script>
export default {
  name: "Record-List",
  components: {},
  props: {
    records: Array,
    fields: Array,
  },
  data() {
    return {};
  },
  methods: {
    handleRowClick(record, index) {
      console.log(index);
      this.$emit("record_selected", index);
    },
    getRowClass(item) {
      if (item.invalid) {
        return "red";
      }
    },
  },
  computed: {
    headers() {
      return this.fields
        .filter(function (el) {
          return el["label"] != null;
        })
        .map((item) => ({ value: item.key, text: item.label }));
    },
  },
};
</script>

<style scoped>
.invalid-row {
  background-color: red !important;
  border-color: red !important;
}
</style>
