<template>
  <v-container class="app">
    <v-row no-gutters>
      <v-col
        v-for="location in locations"
        :key="location.code"
        cols="12"
        sm="4"
      >
        <Crumb
          :isNext="isNext(location)"
          :location="location"
          :btnText="visitedTs(location)"
          v-on="handler"
        ></Crumb>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// @ is an alias to /src

import support from "../plugins/support";
import Crumb from "@/components/Crumb.vue";
export default {
  name: "Survey-Breadcrumb-Form",
  components: { Crumb },
  data() {
    return {
      handler: {
        dropCrumb: this.dropCrumb,
      },
      required: [(v) => !!v || "Required"],
      record: {},
      refTables: this.$store.state.refTables,
      tableName: "survey_breadcrumb",
      last_checked: 0,
    };
  },
  mounted() {},
  methods: {
    dropCrumb(record) {
      record.ts = support.getLocalTimestamp(new Date());
      this.$emit("dropCrumb", record);
    },
    isNext(location) {
      return location.sequence == this.last_checked + 1;
    },
    visitedTs(location) {
      let survey = support.provideRecord(support.SURVEYEFFORT);
      let crumbs = this.$store.state.dataTables["survey_breadcrumb"];
      console.log(crumbs);
      let visit_ts = this.$store.state.dataTables["survey_breadcrumb"]
        .filter(function (el) {
          return (
            el["survey_ts"] == survey.survey_ts &&
            el["survey_tablet"] == survey.survey_tablet &&
            el["breadcrumb_location_code"] == location.code
          );
        })
        .map((item) => item.ts);
      if (visit_ts.length < 1) {
        return "now";
      } else {
        return visit_ts[visit_ts.length - 1].substring(11, 16);
      }
      //this.btntext = new Date().toISOString().substr(11, 5);
    },
  },

  computed: {
    locations() {
      if (this.$store.state.refTables["ref_breadcrumb_location"].length > 2) {
        return this.$store.state.refTables["ref_breadcrumb_location"];
      } else {
        return [];
      }
    },
  },
};
</script>
