<template>
  <div>
    <div class="d-flex">
      <!-- <v-btn icon to="/photo"> -->
      <!-- <v-btn icon @click="openCamera">
        <v-icon>mdi-camera</v-icon>
      </v-btn> -->
      <v-file-input
        v-model="selectedFile"
        class="pt-0 mr-2"
        type="file"
        accept="image/jpeg"
        id="photo-input"
        ref="photo-input"
        prepend-icon="mdi-camera"
        @change="onFileChange"
        hide-input
      />
    </div>
    <canvas
      v-if="imageSrc"
      id="full-size-canvas"
      :width="width"
      :height="height"
      hidden
    />
    <img
      v-if="imageSrc"
      :src="imageSrc"
      id="image-hidden"
      :style="{ width: imageWidth, height: imageHeight }"
      @load="setOriginalSize"
      hidden
    />
    <v-data-table
      :headers="headers"
      :items="records"
      item-key="photo_file_name"
      sort-by="ts"
      group-by="photo_type_code"
      class="elevation-1"
      hide-default-footer
    ></v-data-table>
  </div>
</template>
<script>
import support from "../plugins/support";

export default {
  name: "Photo-List",
  components: {},
  props: {
    records: Array,
  },
  data() {
    return {
      selectedFile: [],
      handler: {},
      imageSrc: null,
      imageWidth: "auto",
      imageHeight: "auto",
      width: 1200, //assume landscape, flip if portrait
      height: 900,
      headers: [
        {
          value: "photo_file_name",
          text: "Photo Name",
        },
      ],
    };
  },
  methods: {
    openCamera() {
      this.$emit("cameraClicked");
    },

    setOriginalSize(event) {
      // Set width and height to the original image size
      this.imageWidth = `${event.target.naturalWidth}px`;
      this.imageHeight = `${event.target.naturalHeight}px`;
      this.drawSmallerCanvas(event.target);
    },
    //photo handling functions in photolist are only for uploaded photos.
    async onFileChange(event) {
      let hasfile = event.name != undefined && event.name != "";
      console.log(hasfile);
      if (!hasfile) {
        console.log("no file selected");
        return;
      }
      const file = this.selectedFile;
      const reader = new FileReader();
      reader.onload = (e) => {
        console.log(e);
        this.imageSrc = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    drawSmallerCanvas(image) {
      const canvas = document.getElementById("full-size-canvas");
      const ctx = canvas.getContext("2d");
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      if (image.naturalWidth > image.naturalHeight) {
        canvas.width = this.width;
        canvas.height = this.height;
        ctx.drawImage(image, 0, 0, this.width, this.height);
      } else {
        canvas.width = this.height;
        canvas.height = this.width;
        ctx.drawImage(image, 0, 0, this.height, this.width);
      }
      this.photoData = canvas
        .toDataURL("image/jpeg")
        .replace("image/jpeg", "image/octet-stream");

      this.$emit("photoAccepted", {
        photo_file_name: this.setFileName(),
        data: this.photoData.replace("data:image/octet-stream;base64,", ""),
      });
    },
    setFileName() {
      const d = new Date();
      return `${this.$store.getters.dbName}_${
        this.$store.getters.tabletCode
      }_${support
        .getLocalTimestamp(d)
        .replace(/ /g, "T")
        .replace(/:/g, "")}.jpeg`;
    },
    // downloadPhoto(obj) {
    //   const filename = obj.photo_file_name;
    //   // old skool DOM manipulation, not great...
    //   const link = document.createElement("a");
    //   link.style.display = "none";
    //   link.href = obj.data;
    //   link.download = filename;
    //   document.body.appendChild(link);
    //   link.click();
    //   setTimeout(() => {
    //     URL.revokeObjectURL(link.href);
    //     link.parentNode.removeChild(link);
    //   }, 0);
    // },
  },
};
</script>
<style scoped>
.flex {
  display: flex;
}
.centered-input input {
  text-align: center;
}
</style>
