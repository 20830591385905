<template>
  <v-container>
    <span :style="setColor()">
      {{ location.title }}
    </span>
    <v-btn
      :label="location.title"
      color="primary"
      dark
      @click="click"
      :disabled="disabled"
    >
      <span :style="setColor()">
        {{ btnText }}
      </span>
    </v-btn>
  </v-container>
</template>

<script>
export default {
  name: "One-Crumb",
  components: {},
  props: {
    location: Object,
    disabled: Boolean,
    isNext: Boolean,
    btnText: String,
  },
  data() {
    return {
      //btntext: "now",
    };
  },

  methods: {
    click() {
      this.$emit("dropCrumb", this.location);
    },
    highlight() {
      return this.isNext;
    },
    setColor() {
      if (this.isNext) {
        this.isColor = "red";
      } else {
        this.isColor = "black";
      }
      return { color: this.isColor };
    },
  },
};
</script>
