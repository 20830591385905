<template>
  <DailySummary></DailySummary>
</template>

<script>
import DailySummary from "@/components/DailySummary";
export default {
  name: "Summary-Form",

  components: { DailySummary },
};
</script>
