<template>
  <v-app id="app">
    <v-app-bar app clipped-left dense color="primary" dark>
      <v-app-bar-title> Downed Bird v{{ version }}</v-app-bar-title>
      <v-tooltip bottom close-delay="2000">
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-on="on" v-bind="attrs" @click="updateNow">
            <v-icon>mdi-update</v-icon>
          </v-btn>
        </template>
        <span> Check for update</span>
      </v-tooltip>
      <v-spacer></v-spacer>
      <v-app-bar-title app>
        <v-tooltip bottom close-delay="2000">
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon to="/" v-on="on" v-bind="attrs">
              <v-icon>mdi-home-circle</v-icon>
            </v-btn>
          </template>
          <span> Return home</span>
        </v-tooltip>
        <v-tooltip bottom close-delay="2000">
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-on="on" v-bind="attrs" @click="save">
              <v-icon>mdi-content-save-all</v-icon>
            </v-btn>
          </template>
          <span> Save and submit data</span>
        </v-tooltip>
        <v-tooltip bottom close-delay="2000">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              to="/search"
              v-on="on"
              v-bind="attrs"
              @click="newSearch"
            >
              <v-icon>mdi-walk</v-icon>
            </v-btn>
          </template>
          <span> Start a new search</span>
        </v-tooltip>
        <!-- <v-tooltip bottom close-delay="2000">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              to="/summary"
              v-on="on"
              v-bind="attrs"
              :disabled="!loggedIn"
            >
              <v-icon>mdi-table</v-icon>
            </v-btn>
          </template>
          <span> View submitted data</span>
        </v-tooltip> -->
      </v-app-bar-title>
      <!-- <div id="nav" class="vld-parent">
        <nav v-if="loggedIn">
          <router-link to="/summary">Summary</router-link>
        </nav>
      </div> -->
    </v-app-bar>
    <v-main>
      <router-view />
      <v-snackbar
        v-model="submittedSnackbar"
        :timeout="submittedTimeout"
        id="records-submitted"
        color="getColor"
        variant="tonal"
      >
        <div class="text pb-2">{{ submittedMessages }}</div>
      </v-snackbar>

      <v-snackbar
        v-model="updateSnackbar"
        :timeout="updateTimeout"
        id="update-ready"
        vertical
      >
        <div class="text-subtitle-1 pb-2">App Update Available</div>
        <v-btn color="indigo" @click="updateApp">Update</v-btn>
      </v-snackbar>
    </v-main>
    <v-footer color="primary" app fixed dark v-if="loggedIn">
      <p class="ma-0 pa-0">
        Logged in as {{ username }} to Database: {{ dbName }}, Tablet code:
        {{ tabletCode }}
      </p>
      <v-spacer />
      <v-icon v-if="gettingLocation" class="good">mdi-map-marker</v-icon>
      <v-icon v-else class="alert">mdi-map-marker-alert</v-icon>
      <v-btn color="success" @click="logout">Logout</v-btn>
    </v-footer>
    <v-footer class="ma-0 pa-0" v-else color="warning" app fixed dark>
      <p class="ma-0 pa-0">
        Not logged in. Database: {{ dbName }}, Tablet code
        {{ tabletCode }}
      </p>
      <v-spacer />
      <v-icon v-if="gettingLocation" class="good">mdi-map-marker</v-icon>
      <v-icon v-else class="alert">mdi-map-marker-alert</v-icon>
      <v-btn color="secondary" @click="loginRedirect($router.currentRoute.path)"
        >Login</v-btn
      >
    </v-footer>
  </v-app>
</template>

<script>
import localforage from "localforage";
//import { extendPrototype } from "localforage-startswith";
import axios from "axios";
//const localforage = extendPrototype(localForage);

// NOTE: this is the base URL, all axios.get/post will need
// this.$store.getters.dbName prepended to route
axios.defaults.baseURL = process.env.VUE_APP_ENDPOINT_URL;
axios.defaults.headers.post["Content-Type"] =
  "application/x-www-form-urlencoded";
axios.defaults.withCredentials = true;
import support from "@/plugins/support";

export default {
  name: "App",
  data: () => ({
    updateSnackbar: false,
    updateTimeout: -1,
    registration: null,
    refreshing: false,
    submittedSnackbar: false,
    submittedTimeout: 6000,
  }),
  created() {
    localforage.config({
      name: "downedBird",
      version: 1,
    });
    document.addEventListener("swUpdated", this.updateAvailable, {
      once: true,
    });
    //verify doesn't work and remove
    navigator.serviceWorker.addEventListener("controllerchange", () => {
      // We'll also need to add 'refreshing' to our data originally set to false.
      if (this.refreshing) return;
      this.refreshing = true;
      // Here the actual reload of the page occurs
      window.location.reload();
    });
    this.$watch("dbName", () => {
      support.populateReferenceTables();
    });
    this.$watch(
      "loggedIn",
      () => {
        console.log(this.$store.getters.username);
      },
      { deep: true }
    );
    this.$store.dispatch("checkAuthentication");
  },
  computed: {
    submittedMessages() {
      return this.$store.getters.getAttribute("submittedMessages");
    },
    gettingLocation() {
      return this.$store.getters.locationOn;
    },
    loggedIn() {
      return this.$store.getters.isLoggedIn;
    },
    version() {
      return this.$store.getters.version;
    },
    dbName() {
      return this.$store.getters.dbName;
    },
    tabletCode() {
      return this.$store.getters.tabletCode;
    },
    username() {
      return this.$store.getters.username;
    },
  },
  async mounted() {
    this.checkPreviousState();
  },
  methods: {
    updateNow() {
      if (this.refreshing) return;
      this.refreshing = true;
      //refreshing the page forces it to check the sw registration
      window.location.reload();
    },
    updateAvailable(e) {
      this.registration = e.detail;
      this.updateSnackbar = true;
    },
    updateApp() {
      this.updateSnackbar = false;
      console.log("snackbar false");
      if (!this.registration || !this.registration.waiting) {
        console.log("not registration");
        return;
      }
      if ("caches" in window) {
        caches.keys().then((names) => {
          names.forEach((name) => {
            caches.delete(name);
          });
        });
      }
      console.log("skip waiting");
      this.registration.waiting.postMessage("skipWaiting");
      //when the update is complete we will reload.
      this.registration.waiting.addEventListener("statechange", (event) => {
        if (event.target.state === "activated") {
          window.location.reload();
        }
      });
    },
    loginRedirect: function (path) {
      this.$router.push({
        name: "login",
        query: { redirect: path },
      });
    },
    logout: function () {
      this.$store.dispatch("logout");
    },
    save() {
      this.submittedSnackbar = true;
      support.submitData();
    },
    newSearch() {
      support.newSurveyEffort();
    },
    checkPreviousState() {
      support.findUnfinishedSearch(new Date());
    },
  },
};
</script>

<style>
html {
  overflow-y: auto !important;
  overflow: hidden;
  overscroll-behavior: none;
}
.v-application--wrap {
  min-height: 100% !important;
}
body {
  overscroll-behavior: contain;
}

#nav {
  padding: 30px;
  text-align: center;
}

#nav a {
  font-weight: bold;
  color: #f9fafc;
}

#nav a.router-link-exact-active {
  color: #ab42b9;
}

i.v-icon.alert {
  color: red;
}
i.v-icon.good {
  color: green;
}
</style>
