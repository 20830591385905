import Vue from "vue";
import VueRouter from "vue-router";
import HelpView from "../views/HelpView.vue";
import Login from "@/components/Login.vue";
import Search from "../views/Search.vue";
import HomeView from "../views/HomeView.vue";
import Summary from "../views/Summary.vue";
import store from "../store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/help",
    name: "help",
    component: HelpView,
  },
  {
    path: "/login/",
    name: "login",
    component: Login,
  },
  {
    path: "/search",
    name: "search",
    component: Search,
  },
  {
    path: "/summary",
    name: "Summary",
    component: Summary,
  },
];

const router = new VueRouter({
  //mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  console.log(from);
  if (
    // make sure the user is authenticated
    store.getters.dbName == null &&
    (to.name == "search" || to.name == "summary") &&
    from.name == null
  ) {
    console.log("sending you home");
    // redirect the user to the login page
    next("/");
  } else if (
    // make sure the user is authenticated
    store.getters.dbName == null &&
    (to.name == "search" || to.name == "summary") &&
    from.name == "home"
  ) {
    console.log("sending you home");
    // redirect the user to the login page
    next("/");
  } else next();
});

export default router;
