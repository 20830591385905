<template>
  <div class="help">
    <h1>You are using app version: {{ version }}</h1>
  </div>
</template>

<script>
// @ is an alias to /src
export default {
  name: "Help-Form",
  data() {},
  computed: {
    version() {
      return this.$store.getters.version;
    },
  },
};
</script>
