<template>
  <v-container>
    <v-dialog v-model="hasUnfinishedSearch">
      <v-card>
        <v-card-title><span class="text-h5">Resume Search</span></v-card-title>
        <v-card-text> Do You want to resume your last search?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="resumeSearch"> Yes </v-btn>
          <v-btn color="blue darken-1" text @click="leaveOldSearch"> No</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <SurveyEffort
      :selected_record="survey_effort_record"
      :notSearch="notSearch"
      v-on="handler"
    />
    <BreadCrumb
      v-if="!notSearch & !asBoolean(survey_effort_record.incidental)"
      :selected_record="survey_effort_record"
      v-on="handler"
    />
    <v-tabs v-model="tab">
      <v-tabs-slider color="blue"></v-tabs-slider>
      <v-tab v-for="page in pages" :key="page"> {{ page }}</v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item v-for="page in pages" :key="page">
        <ObservationForm
          v-if="survey_effort_record.insert_ts != ''"
          :surveyTs="survey_effort_record.survey_ts"
          :tabType="page"
          v-on="handler"
          :notSearch="notSearch"
        />
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<script>
import support from "../plugins/support";
import SurveyEffort from "@/components/SurveyEffort";
import BreadCrumb from "@/components/BreadCrumb";
import ObservationForm from "@/components/Observation";

export default {
  name: "Search-Form",

  components: {
    SurveyEffort,
    BreadCrumb,
    ObservationForm,
  },
  props: {
    datatype: String,
  },
  created() {},
  data() {
    return {
      handler: {
        dropCrumb: this.dropCrumb,
      },
      tab: null,
      required: [(v) => !!v || "Required"],
      pages: ["Downed Bird", "Predator", "Turtle"],
    };
  },
  computed: {
    hasUnfinishedSearch: {
      get() {
        return this.$store.getters.getAttribute("unfinishedSearch") != null;
      },
      set(value) {
        console.log(value);
      },
    },
    hasSurvey() {
      if (
        this.notSearch &
        (this.survey_effort_record.reporter_name != "" &&
          this.survey_effort_record.reporter_name != "guest")
      ) {
        return true;
      } else if (!this.notSearch & (this.insert_ts != "")) {
        return true;
      } else {
        return false;
      }
    },
    survey_effort_record() {
      return support.provideRecord(support.SURVEYEFFORT);
    },
    notSearch() {
      return !this.$store.getters.isLoggedIn;
    },
    dbName() {
      return this.$store.getters.dbName;
    },
    databaseSet() {
      return !(
        this.dbName == undefined ||
        this.dbName == null ||
        this.dbName == ""
      );
    },
  },
  async mounted() {
    support.startWatch();
    this.kickback();
    this.$watch(
      "dbName",
      () => {
        this.kickback();
      },
      { deep: true }
    );
    this.$watch(
      "survey_effort_record",
      () => {
        console.log("record changed" + this.survey_effort_record.insert_ts);
      },
      { deep: true }
    );
    if (!this.notSearch && this.survey_effort_record.observer_code == "guest") {
      console.log(this.$store.getters.username);
      console.log(this.survey_effort_record.observer_code);
      this.survey_effort_record.observer_code == this.$store.getters.username;
    }
  },
  methods: {
    async restoreSearch() {
      let self = this;
      let surveyts = this.$store.state.unfinishedSearch.survey_ts;
      let promises = [];
      Object.keys(this.$store.state.dataTables).forEach(function (key) {
        //get the local records
        promises.push(
          new Promise((resolve) => {
            support.getLocal(key).then((records) => {
              let currentRecords = records.filter((rec) => {
                return rec.survey_ts === surveyts;
              });
              self.$store.commit("refreshRecords", {
                tableName: key,
                records: currentRecords,
                tableType: "dataTables",
              });
              resolve();
            });
          })
        ); //promise captured in array
      });
      return Promise.all(promises).then((values) => {
        return values[values.length - 1];
      });
    },
    async resumeSearch() {
      let self = this;
      this.restoreSearch().then(() => {
        let newposition = support.findRecordPosition(
          "survey_effort",
          self.$store.state.unfinishedSearch
        );
        self.$store.commit("shiftPosition", {
          table: "survey_effort",
          position: newposition,
        });
        self.$store.commit("setAttribute", {
          attribute: "unfinishedSearch",
          value: null,
        });
      });
    },

    leaveOldSearch() {
      this.$store.commit("setAttribute", {
        attribute: "unfinishedSearch",
        value: null,
      });
    },
    dropCrumb(crumbInfo) {
      support.saveRecord(support.SURVEYEFFORT, this.survey_effort_record);
      let record = support.newBreadCrumb();
      record.breadcrumb_location_code = crumbInfo.code;
      record.ts = crumbInfo.ts;
      support.saveRecord(support.SURVEYBREADCRUMB, record);
      support.submitData();
    },
    asBoolean(b) {
      return support.asBoolean(b);
    },
    kickback() {
      if (!this.databaseSet) {
        this.$router.push({ name: "home" });
      }
    },
  },
};
</script>
