<template>
  <v-container class="app">
    <v-col cols="12">
      <v-row>
        <v-col cols="12" md="9">
          <v-btn
            v-if="!openCamera"
            color="primary"
            dark
            @click="newObservation"
          >
            <span>New Observation</span>
          </v-btn>
          <v-form ref="observation">
            <DownedBirdForm
              v-if="
                (tabType == 'Downed Bird') &
                (currentRecord.observation_ts != '')
              "
              :selected_record="currentRecord"
              :records="currentPhotos"
              :notSearch="notSearch"
              v-on="handler"
            ></DownedBirdForm>
            <PredatorForm
              v-if="
                (tabType == 'Predator') & (currentRecord.observation_ts != '')
              "
              v-on="handler"
              :selected_record="currentRecord"
              :records="currentPhotos"
              :notSearch="notSearch"
            />
            <TurtleForm
              v-if="
                (tabType == 'Turtle') & (currentRecord.observation_ts != '')
              "
              v-on="handler"
              :notSearch="notSearch"
              :selected_record="currentRecord"
              :records="currentPhotos"
            />
          </v-form>
        </v-col>
        <v-col cols="10" md="3">
          <PhotoList
            v-if="currentRecord.observation_ts != ''"
            :records="currentPhotos"
            v-on="handler"
          />
        </v-col>
      </v-row>
      <v-btn
        v-if="currentRecord.observation_ts != ''"
        color="primary"
        dark
        @click="save"
      >
        <span>Done</span>
      </v-btn>
      <v-row>
        <RecordList :records="records" :fields="fields" v-on="handler" />
      </v-row>
      <PhotoTypeDialog
        :showPhotoDialog="assignPhotoType"
        v-on="handler"
      ></PhotoTypeDialog>
      <TakePhoto
        v-if="openCamera"
        :previewHeight="600"
        :previewWidth="800"
        :maxheight="maxheight"
        :maxwidth="maxwidth"
        :autoDownload="true"
        :isCameraOpen="openCamera"
        v-on="handler"
      ></TakePhoto>
    </v-col>
  </v-container>
</template>

<script>
import DownedBirdForm from "@/components/DownedBirdForm";
import PredatorForm from "@/components/PredatorForm";
import TurtleForm from "@/components/TurtleForm";
import RecordList from "@/components/RecordsList";
import TakePhoto from "../components/TakePhoto";
import PhotoList from "@/components/PhotoList.vue";
import PhotoTypeDialog from "@/components/PhotoTypeDialog.vue";
import support from "@/plugins/support";

export default {
  name: "Observation-Form",
  components: {
    DownedBirdForm,
    PredatorForm,
    TurtleForm,
    RecordList,
    TakePhoto,
    PhotoList,
    PhotoTypeDialog,
  },
  props: {
    tabType: String,
    notSearch: Boolean,
    surveyTs: String,
  },
  data() {
    return {
      handler: {
        record_selected: this.handleSelectedRecord,
        cameraClicked: this.onCameraClicked,
        photoAccepted: this.onPhotoAccepted,
        //@photoAccepted="onPhotoAccepted($event)"
        cameraClose: this.closeCamera,
        photoTypeSet: this.onPhotoTypeAssigned,
      },
      maxwidth: 2200,
      maxheight: 1900,
      observation_ts: "",
      openCamera: false,
      assignPhotoType: false,
      currentPhoto: {},
    };
  },
  methods: {
    onCameraClicked() {
      this.openCamera = true;
    },
    closeCamera() {
      let tmp = this.$store.state.dataTables[support.SURVEYDOWNEDBIRDPHOTO];
      console.log(tmp);
      this.openCamera = false;
    },
    onPhotoAccepted(photoInfo) {
      this.currentPhoto = photoInfo;
      this.assignPhotoType = true;
      this.currentPhoto.observation_ts = this.currentRecord.observation_ts;
      this.currentPhoto.survey_tablet = this.currentRecord.survey_tablet;
      this.currentPhoto.ts = support.getLocalTimestamp(new Date());
      this.$store.commit("addRecord", {
        table: this.getPhotoTable(),
        record: this.currentPhoto,
      });
      support.savePhotoLocally(this.getPhotoTable(), this.currentPhoto);
    },
    onPhotoTypeAssigned(photoType) {
      this.assignPhotoType = false;
      if (photoType != null) {
        this.currentPhoto.observation_ts = this.currentRecord.observation_ts;
        this.currentPhoto.survey_tablet = this.currentRecord.survey_tablet;
        this.currentPhoto.photo_type = photoType;
        this.currentPhoto.ts =
          this.currentPhoto.ts === "" || this.currentPhoto.ts === undefined
            ? support.getLocalTimestamp(new Date())
            : this.currentPhoto.ts;
        //support.savePhotoLocally(this.getPhotoTable(), this.currentPhoto);
      }
      this.currentPhoto = null;
    },
    handleSelectedRecord(index) {
      this.$store.commit("shiftPosition", {
        table: this.getTable(),
        position: index.index,
      });
    },
    newObservation() {
      if (this.currentRecord.observation_ts === "") {
        this.currentRecord.observation_ts = support.getLocalTimestamp(
          new Date()
        );
      } else {
        support.makeNewObservation(this.tabType);
        this.currentRecord["observation_ts"] = support.getLocalTimestamp(
          new Date()
        );
      }
    },
    save() {
      //save the record
      if (
        this.getTable() === "survey_downed_bird" &&
        this.currentRecord.field_species_code === ""
      ) {
        //Don't save observations without a species
      } else {
        support.saveRecord(this.getTable(), this.currentRecord);
        support.submitData();
        //clear the form by resetting the list position
        this.$store.commit("shiftPosition", {
          table: this.getTable(),
          position: -1,
        });
      }
    },
    getTable() {
      switch (this.tabType) {
        case "Downed Bird":
          return support.SURVEYDOWNEDBIRD;
        case "Turtle":
          return support.SURVEYTURTLE;
        case "Predator":
          return support.SURVEYPREDATOR;
      }
    },
    getPhotoTable() {
      switch (this.tabType) {
        case "Downed Bird":
          return support.SURVEYDOWNEDBIRDPHOTO;
        case "Turtle":
          return support.SURVEYTURTLEPHOTO;
        case "Predator":
          return support.SURVEYPREDATORPHOTO;
      }
    },
  },
  computed: {
    records() {
      let surveyTs = this.surveyTs;
      return this.$store.state.dataTables[this.getTable()].filter(function (
        item
      ) {
        return item["survey_ts"] === surveyTs && item["observation_ts"] !== "";
      });
    },
    fields() {
      return support.getFields(this.getTable());
    },
    currentPhotos() {
      let photo_records = this.$store.state.dataTables[this.getPhotoTable()];
      let obstime = this.currentRecord.observation_ts;
      return photo_records.filter(function (el) {
        return el["observation_ts"] === obstime;
      });
    },
    currentRecord() {
      return support.provideRecord(this.getTable());
    },
  },
};
</script>
