import Vue from "vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import Cookies from "vue-cookies";
import { Datetime } from "vue-datetime";
import "vue-datetime/dist/vue-datetime.css";
import "vuetify/dist/vuetify.css";
// eslint-disable-next-line
import styles from "./styles/source_sans_pro.css";
import App from "./App.vue";

Vue.config.productionTip = false;

Vue.use(Cookies);
Vue.use(Datetime);

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
