<template>
  <v-container class="app">
    <v-col cols="12">
      <v-row>
        <v-col cols="12">
          <v-form ref="predator">
            <v-row>
              <v-col cols="12" md="6">
                <v-select
                  v-model="record.predator_code"
                  :items="refTables['ref_predator_species']"
                  item-text="title"
                  item-value="code"
                  filled
                  label="Predator type:"
                  clearable
                  @input="submit"
                ></v-select>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="record.observation_ts"
                  filled
                  label="Observation Time:"
                  required="true"
                  :rules="required"
                  clearable
                  @input="submit"
                  :visible="true"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="record.predator_count"
                  filled
                  type="number"
                  label="Count:"
                  clearable
                  @input="submit"
                  :visible="true"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="record.predator_location"
                  filled
                  label="Location Description:"
                  clearable
                  @input="submit"
                  :visible="true"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="record.predator_other"
                  filled
                  label="Predator Description:"
                  clearable
                  @input="submit"
                  :visible="true"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="record.comment"
                  filled
                  label="Comment:"
                  clearable
                  @input="submit"
                  :visible="true"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-col>
      </v-row>
    </v-col>
  </v-container>
</template>

<script>
// @ is an alias to /src
import support from "@/plugins/support";
export default {
  name: "Predator-Form",
  props: {
    photo_records: Array,
    selected_record: Object,
    notSearch: Boolean,
  },
  data() {
    return {
      handler: {
        submit: this.submit,
      },
      required: [(v) => !!v || "Required"],
      refTables: this.$store.state.refTables,
      tableName: "survey_predator",
      record: this.selected_record,
    };
  },
  mounted() {
    this.$watch(
      "selected_record",
      () => {
        this.record = this.selected_record;
      },
      { deep: true }
    );
  },
  methods: {
    submit() {
      let record = support.forceFieldTypes(support.SURVEYPREDATOR, this.record);
      support.saveRecord(support.SURVEYPREDATOR, record);
    },
    onDateTimeSet(value, parameter) {
      this.record[parameter] = support.getLocalTimestamp(new Date(value));
    },
  },

  computed: {},
};
</script>
