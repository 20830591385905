<template>
  <v-dialog v-model="showDialog" persistent max-width="600px">
    <v-card>
      <v-card-title
        ><span class="text-h5">Select Photo Type</span></v-card-title
      >
      <v-card-text>
        <v-container>
          <v-row>
            <v-col>
              <v-select
                :items="$store.state.refTables.ref_photo_type"
                item-text="title"
                item-value="code"
                label="Photo type"
                filled
                v-model="photoType"
                required
                @input="setSelected"
              ></v-select>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="setSelected"> Save </v-btn>
        <v-btn color="blue darken-1" text @click="discard"> Discard </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "HelloWorld",
  components: {},
  props: {
    showPhotoDialog: Boolean,
  },

  data: () => ({
    photoType: "close",
  }),
  computed: {
    showDialog: {
      get() {
        return this.showPhotoDialog;
      },
      set() {},
    },
  },
  methods: {
    setSelected() {
      this.$emit("photoTypeSet", this.photoType);
      //this.photoType = "close";
    },
    discard() {
      this.$emit("photoTypeSet", null);
      //this.photoType = "close";
    },
  },
};
</script>
