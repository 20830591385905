<template>
  <v-dialog
    ref="dialog"
    v-model="modal2"
    :return-value.sync="datetime"
    persistent
    visible
  >
    <!-- <v-tooltip top> -->
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        :label="$t(label)"
        v-bind:value="$t(datetime)"
        prepend-icon="mdi-calander-outline"
        readonly
        filled
        clearable
        v-bind="attrs"
        v-on="on"
        @input="broadcastDate"
      ></v-text-field>
    </template>
    <span>{{ tooltip }}</span>
    <!--</v-tooltip> -->
    <v-row>
      <v-col>
        <v-date-picker v-if="modal2" v-model="startdate" full-width>
          <v-spacer></v-spacer>
        </v-date-picker>
      </v-col>
      <v-col>
        <v-time-picker v-if="modal2" v-model="starttime" full-width>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="modal2 = false"> Cancel </v-btn>
          <!-- <v-btn text color="primary" @click="$refs.dialog.save(datetime)"> -->
          <v-btn text color="primary" @click="broadcastDate(datetime)">
            OK
          </v-btn>
        </v-time-picker>
      </v-col>
    </v-row>
  </v-dialog>
</template>

<script>
import support from "@/plugins/support";
export default {
  name: "ABRDatePicker",
  props: {
    ts: String,
    label: String,
    tooltip: String,
  },
  created() {
    // this.$watch(
    //   "startdate",
    //   () => {
    //     this.broadcastDate();
    //   },
    //   { deep: true }
    // );
    // this.$watch(
    //   "starttime",
    //   () => {
    //     this.broadcastDate();
    //   },
    //   { deep: true }
    // );
  },

  data() {
    return {
      date: "",
      time: "",
      startdate: support.getLocalTimestamp(new Date()).substring(0, 10),
      starttime: support.getLocalTimestamp(new Date()).substring(11, 16),
      modal2: false,
    };
  },
  computed: {
    datetime: {
      get() {
        return this.date + " " + this.time;
      },
      set(value) {
        console.log(value);
      },
    },
  },
  mounted() {
    this.parseTS(this.ts);
    this.parseInput(this.ts);
  },
  methods: {
    $t(value) {
      return value;
    },
    broadcastDate() {
      this.date = this.startdate;
      this.time = this.starttime;
      this.$emit("datetimeset", this.datetime);
      this.modal2 = false;
    },

    parseTS(ts) {
      if (ts == undefined || ts.length < 1) {
        this.startdate = support.getLocalTimestamp(new Date()).substring(0, 10);
        this.starttime = support
          .getLocalTimestamp(new Date())
          .substring(11, 16);
      } else {
        this.startdate = support
          .getLocalTimestamp(new Date(ts))
          .substring(0, 10);
        this.starttime = support
          .getLocalTimestamp(new Date(ts))
          .substring(11, 16);
      }
    },
    parseInput(ts) {
      if (ts == undefined || ts.length < 1 || ts.includes("NA")) {
        this.date = "";
        this.time = "";
      } else {
        this.date = support.getLocalTimestamp(new Date(ts)).substring(0, 10);
        this.time = support.getLocalTimestamp(new Date(ts)).substring(11, 16);
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
