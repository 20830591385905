<template>
  <v-container class="app">
    <v-col cols="12">
      <v-row>
        <v-col cols="12">
          <v-form ref=" turtle">
            <v-row>
              <v-col cols="12" md="6">
                <v-select
                  v-model="record.turtle_code"
                  :items="refTables['ref_turtle_species']"
                  item-text="title"
                  item-value="code"
                  filled
                  label="Turtle type"
                  clearable
                  @input="submit"
                ></v-select>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="record.observation_ts"
                  filled
                  label="Observation Time:"
                  required="true"
                  :rules="required"
                  clearable
                  @input="submit"
                  :visible="true"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="3">
                <ABRCheckbox
                  v-model="record.tracks"
                  filled
                  label="Tracks:"
                  clearable
                  @input="submit"
                  :visible="true"
                ></ABRCheckbox>
              </v-col>
              <v-col cols="12" md="3">
                <ABRCheckbox
                  v-model="record.nest"
                  filled
                  label="Nest:"
                  clearable
                  @input="submit"
                  :visible="true"
                ></ABRCheckbox>
              </v-col>
              <v-col cols="12" md="3">
                <ABRCheckbox
                  v-model="record.animal"
                  filled
                  label="Animal:"
                  clearable
                  @input="submit"
                  :visible="true"
                ></ABRCheckbox>
              </v-col>
              <v-col cols="12" md="3">
                <ABRCheckbox
                  v-model="record.false_crawl"
                  filled
                  label="False Crawl:"
                  clearable
                  @input="submit"
                  :visible="true"
                ></ABRCheckbox>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="record.turtle_location"
                  filled
                  label="Location Description:"
                  clearable
                  @input="submit"
                  :visible="true"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="record.distance_water"
                  filled
                  label="Distance From Water (m):"
                  type="Number"
                  clearable
                  @input="submit"
                  :visible="true"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="record.comment"
                  filled
                  label="Comment:"
                  clearable
                  @input="submit"
                  :visible="true"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-col>
      </v-row>
    </v-col>
  </v-container>
</template>

<script>
// @ is an alias to /src
import support from "@/plugins/support";
import ABRCheckbox from "./ABRCheckbox.vue";
export default {
  name: "Turtle-Form",
  props: {
    photo_records: Array,
    selected_record: Object,
    notSearch: Boolean,
  },
  data() {
    return {
      handler: {
        submit: this.submit,
      },
      required: [(v) => !!v || "Required"],
      refTables: this.$store.state.refTables,
      tableName: "survey_ turtle",
      record: this.selected_record,
    };
  },
  mounted() {
    this.$watch(
      "selected_record",
      () => {
        this.record = this.selected_record;
      },
      { deep: true }
    );
  },
  methods: {
    submit() {
      let record = support.forceFieldTypes(support.SURVEYTURTLE, this.record);
      support.saveRecord(support.SURVEYTURTLE, record);
    },
    onDateTimeSet(value, parameter) {
      this.record[parameter] = support.getLocalTimestamp(new Date(value));
    },
  },
  computed: {},
  components: { ABRCheckbox },
};
</script>
